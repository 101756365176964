import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import popupPlugin from '@/utils/popupPlugin';

import './styles/reset.css';
import './styles/common.less';
import './styles/media.css';

import './views/phone/styles/phone-common.less';

const app = createApp(App);

app.config.productionTip = false;

// SEO优化
import MetaInfo from 'vue-meta-info';
app.use(MetaInfo);

// h1标签
import h1Title from '@/views/pc/components/h1-title.vue';
app.component('h1Title', h1Title);
app.use(router);
app.use(popupPlugin);
app.mount('#app');
