const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function () {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

const debounce = (func, delay) => {
  let timer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      func.apply(context, args);
    }, delay);
  };
};

// 深度拷贝
const deepClone = (obj, hash = new WeakMap()) => {
  // 基本类型直接返回（非对象类型）
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  // 如果对象已经拷贝过，直接返回拷贝的结果，避免循环引用
  if (hash.has(obj)) {
    return hash.get(obj);
  }

  // 处理 Date 对象
  if (obj instanceof Date) {
    return new Date(obj);
  }

  // 处理 RegExp 对象
  if (obj instanceof RegExp) {
    return new RegExp(obj);
  }

  // 创建新对象/数组用于存放拷贝的结果
  const cloneObj = Array.isArray(obj) ? [] : {};

  // 将原对象存储到 WeakMap 中
  hash.set(obj, cloneObj);

  // 获取 Object.prototype.hasOwnProperty 方法
  const hasOwn = Object.prototype.hasOwnProperty;

  // 遍历并深拷贝
  for (let key in obj) {
    // 使用安全的方法检查属性
    if (hasOwn.call(obj, key)) {
      cloneObj[key] = deepClone(obj[key], hash); // 递归调用
    }
  }

  return cloneObj;
};

export { throttle, debounce, deepClone };
