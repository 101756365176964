<template>
  <transition name="popup-move">
    <div v-show="visible" :class="{ showPopup: visible }" class="popup-content">
      <div class="popup-header" v-if="title">
        <h3>{{ title }}</h3>
        <!-- <button @click="close">X</button> -->
      </div>
      <div class="popup-body">
        <slot>
          <div class="icon">
            <img :src="iconImg" alt="" />
          </div>
          <span class="text">{{ content }}</span>
        </slot>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, computed } from 'vue';
// const visible = ref(false);
// const title = ref('');
// const type = ref();
// const delay = ref(null);
const props = defineProps({
  visible: Boolean,
  title: String,
  type: String,
});

const iconImg =
  props.type === 'success'
    ? '/image/contact/checj.svg'
    : '/image/contact/fail.svg';
</script>
<style lang="less" scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 999;
  //   pointer-events: none;
}

.popup-content {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  text-align: center;
  color: #222222;
  font-size: 16px;
  width: 250px;
  height: 80px;
  box-sizing: border-box;
  transform: scale(0.5); /* 初始缩放为0.5 */
  transition: transform 0.3s ease; /* 添加动画效果 */
}

.showPopup {
  transform: scale(1); /* 展开时设置缩放为1 */
}

.popup-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.popup-header h3 {
  margin: 0;
}

.popup-body {
  font-size: 16px;
  color: #222222;
  font-weight: bold;
  .icon {
    padding: 5px 0;

    img {
      width: 24px;
      height: 24px;
    }
  }
  .text {
    padding-top: 10px;
  }
}

.popup-fade-enter-active,
.popup-fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.popup-fade-enter,
.popup-fade-leave-to {
  opacity: 0;
  //   transform: scale(0.9);
}

.popup-move-enter-active,
.popup-move-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.popup-move-enter,
.popup-move-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
