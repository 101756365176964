<template>
  <h1>
    <span><slot></slot></span>
  </h1>
</template>

<script>
export default {
  name: "H1",
  props: {
    mb: {
      type: Number,
      default: 13,
    },
  },
  computed: {
    marginBottom() {
      return this.mb + "px";
    },
  },
};
</script>

<style lang="less" scoped>
h1 {
  font-size: 40px;
  line-height: 56px;
  margin-bottom: v-bind(marginBottom);
  span {
    position: relative;
    padding-left: 24px;
    &::after {
      content: "";
      display: block;
      height: 40px;
      width: 4px;
      background-color: #e83021;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50% + 4px);
    }
  }
}
</style>
