<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script setup>
import { onMounted } from 'vue';
onMounted(() => {
  document.dispatchEvent(new Event('render-event'));
});
</script>
