<template>
  <div class="layout" @click="handleClick">
    <!-- 顶部导航栏 -->
    <Header :is-scroll="isScroll"></Header>
    <!-- 动态内容 -->
    <Totop />
    <main>
      <transition name="fade">
        <RouterView />
      </transition>
    </main>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script setup>
import Header from './components/Header';
import Totop from '../components/Totop';
import Footer from './components/Footer';
import { ref, provide } from 'vue';

const now = ref(0);
provide('now', now);

const handleClick = () => {
  now.value = Date.now();
};

let isScroll = ref(false);
const handleScroll = () => {
  if (window.scrollY > 50) {
    isScroll.value = true;
  } else {
    isScroll.value = false;
  }
};

window.addEventListener('scroll', handleScroll);
</script>
<style lang="less" scoped>
.layout {
  position: relative;
  min-width: 375px;
}
main {
  min-height: calc(100vh - 500px);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
