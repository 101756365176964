<template>
  <footer>
    <div class="f-border">
      <div class="footer_wrapper">
        <div class="red-box"></div>
      </div>
    </div>
    <div class="footer_wrapper">
      <div class="f-top">
        <div class="f-logo">
          <img src="/image/logo0.png" alt="arcsoft" />
        </div>
        <div class="f-content">
          <div class="f-row row-1">
            <ul
              v-for="(navItem, index) in navList.slice(0, 4)"
              :key="index"
              class="content-item"
            >
              <li
                class="item-title"
                :class="{ 'active-route': navItem.key === currentRouteName }"
              >
                <router-link :to="getRoutePath(navItem.key)">
                  {{ navItem.name }}
                </router-link>
              </li>
              <li
                v-for="(childItem, childIndex) in navItem.children || []"
                :key="childIndex"
                :class="{ 'active-route': childItem.key === currentRouteName }"
              >
                <router-link :to="getRoutePath(childItem.key)">
                  {{ childItem.name }}
                </router-link>
              </li>
            </ul>
          </div>

          <div class="f-row row-2">
            <ul
              v-for="(navItem, index) in navList.slice(4)"
              :key="index"
              class="content-item"
            >
              <li
                class="item-title"
                :class="{ 'active-route': navItem.key === currentRouteName }"
              >
                <router-link :to="getRoutePath(navItem.key)">
                  {{ navItem.name }}
                </router-link>
              </li>
              <li
                v-for="(childItem, childIndex) in navItem.children || []"
                :key="childIndex"
                :class="{ 'active-route': childItem.key === currentRouteName }"
              >
                <router-link :to="getRoutePath(childItem.key)">
                  {{ childItem.name }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="f-footer">
        <span class="copy-right"
          >©2024 ArcSoft Corporation Limited. All rights reserved.</span
        >
        <span
          ><a href="/home">English</a> |
          <a href="http://www.arcsoft.com.cn">简体中文</a></span
        >
        <span class="right-text"
          ><a href="/privacy-policy">Privacy Policy</a> |
          <a href="/terms-of-use">Terms of Use</a>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
import { routePath } from '@/router/routePath.js';
export default {
  name: 'Footer',
  data() {
    return {
      navList: [
        {
          name: 'Automotive Visual Solutions',
          key: 'AutomotiveVisualSolutions',
          children: [
            {
              name: 'In-cabin Visual Software Solutions',
              key: 'InCabin',
            },
            {
              name: 'Auto-Parking Assist (APA) Solutions on a Single SoC',
              key: 'APA',
            },
            {
              name: 'Integrated ADAS Solutions',
              key: 'ADAS',
            },
            {
              name: 'Integrated Solutions for Commercial Vehicles',
              key: 'CommercialVehicles',
            },
          ],
        },
        {
          name: 'Partnership',
          key: 'Partnership',
        },
        {
          name: 'About us',
          key: 'AboutUs',
        },
        {
          name: 'Contact',
          key: 'Contact',
        },
        {
          name: 'Computational Photography Solutions for Smartphone',
          key: 'SmartphoneVisualSolutions',
          children: [
            {
              name: 'Turbo Fusion™ Products for Smartphone',
              key: 'TurboFusion',
            },
            {
              name: 'AIGC Large Model Solutions for Smartphone',
              key: 'AigcSolutions',
            },
          ],
        },
      ],
    };
  },
  computed: {
    currentRouteName() {
      // console.log(this.$route.name);
      return this.$route.name;
    },
  },
  methods: {
    getRoutePath(key) {
      return routePath[key] || '';
    },
  },
};
</script>
<style lang="less" scoped>
footer {
  background-color: #fafafa;
  height: 400px;
  .f-border {
    height: 4px;
    width: 100%;
    background-color: #222222;
    .red-box {
      width: 180px;
      height: 100%;
      background-color: #e83021;
    }
  }
  .f-top {
    display: flex;
    .f-logo {
      width: 180px;
      height: 48px;
      margin-right: 90px;
      flex-shrink: 0;
      margin-top: 45px;
      font-size: 24px;
      text-align: center;
      img {
        width: 100%;
      }
    }
    .f-content {
      padding-top: 60px;
      width: 100%;
      box-sizing: border-box;
      .f-row {
        display: flex;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .content-item {
        // min-width: 220px;
        flex: 1;
        line-height: 20px;
        margin-bottom: 8px;
        color: #555555;
        white-space: nowrap;
        // margin-right: 14px;
        padding-right: 14px;
        &:last-child {
          margin-right: 0;
        }
        .item-title {
          a {
            font-size: 20px;
          }
          margin-bottom: 18px;
          color: #222222;
        }
      }
    }
  }

  .f-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    // background-color: #f0f0f0;
    line-height: 40px;
    color: #a5a5a5;
    padding: 0 15px;
    box-sizing: border-box;
    font-size: 14px;
    .copy-right {
      margin-right: 40px;
    }
    .right-text {
      float: right;
    }
  }

  .footer_wrapper {
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;
    position: relative;
  }
}
.active-route a {
  color: #a5a5a5;
}
// @media (max-width: 1024px) {
//   .footer_wrapper {
//     max-width: 960px;
//   }
// }
</style>
