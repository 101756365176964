import { createVNode, render } from 'vue';
import Popup from '@/views/pc/components/Popup.vue';

const popupPlugin = {
  install: (app, options) => {
    app.config.globalProperties.$popup = {
      open(options) {
        let mountNode = document.createElement('div');
        const PopupConstructor = createVNode(Popup, { ...options });
        render(PopupConstructor, mountNode);
        document.body.appendChild(mountNode);

        setTimeout(() => {
          document.body.removeChild(mountNode);
          mountNode = null;
        }, options.delay);
        // console.log(PopupConstructor, options);
        // PopupConstructor.component.ctx.visible = true;
        // PopupConstructor.open(options);
      },
      close() {
        // PopupConstructor.close();
      },
    };
  },
};

export default popupPlugin;
