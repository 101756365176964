<template>
  <header id="header">
    <div :class="props.isScroll ? 'scrolled top_wrapper' : 'top_wrapper'">
      <div class="logo">
        <router-link to="/phone/home">
          <img src="/image/logo.png" @click.stop="logoNav" alt="arcsoft" />
        </router-link>
      </div>
      <div class="nav" @click="handleNav">
        <img
          :src="
            props.isScroll
              ? '/image/phone/icon/nav-b.svg'
              : '/image/phone/icon/nav.svg'
          "
          alt=""
        />
      </div>
    </div>
    <Transition name="fade">
      <nav v-if="showNav" class="nav-warpper" @click.stop="handleNav">
        <TransitionGroup name="fade" tag="div">
          <ul
            class="nav-list"
            v-show="index === navList.length - 1"
            v-for="(item, index) in navList"
            :key="index"
          >
            <p v-show="index > 0" class="back" @click.stop="handleBack(index)">
              <img src="/image/phone/icon/arrow.svg" />Back
            </p>
            <div v-for="(item_I, itemIdx) in item" :key="itemIdx">
              <div class="nav-item">
                <p
                  @click.stop="handleChildren(item_I, index)"
                  v-if="item_I.children"
                  class="nav-title"
                >
                  {{ item_I.name }}
                </p>
                <span v-else @click="toPage({ name: item_I.key })">
                  {{ item_I.name }}
                </span>
              </div>
              <li
                v-if="index > 0"
                v-for="(sub, sub_i) in item_I.children"
                class="sub-item"
                :key="sub_i"
              >
                <span @click="toPage({ name: sub.key })">
                  {{ sub.name }}
                </span>
              </li>
            </div>
            <div class="item-language">
              <a href="/phone/home">English</a>
              | <a href="https://www.arcsoft.com.cn/">简体中文</a>
            </div>
          </ul>
        </TransitionGroup>
      </nav>
    </Transition>
  </header>
</template>

<script setup>
// import { routePath } from '@/router/routePath.js';
import { ref, defineProps, watch } from 'vue';
import { useRouter } from 'vue-router';

const oldNav = [
  [
    {
      name: 'Automotive Visual Solutions',
      toPath: '',
      key: 'ProductSolutions',
      children: [
        {
          name: 'Automotive Visual Solutions',
          toPath: '',
          key: 'AutomotiveVisualSolutions',
          children: [
            {
              name: 'In-cabin Visual Software Solutions',
              toPath: '',
              key: 'MInCabin',
            },
            {
              name: 'Auto-Parking Assist (APA) Solutions on a Single SoC',
              toPath: '',
              key: 'MAPA',
            },
            {
              name: 'Integrated ADAS Solutions',
              toPath: '',
              key: 'MADAS',
            },
            {
              name: 'Integrated Solutions for Commercial Vehicles',
              toPath: '',
              key: 'MCommercialVehicles',
            },
          ],
        },
      ],
    },
    {
      name: 'Computational Photography Solutions for Smartphone',
      toPath: '',
      key: 'SmartphoneVisualSolutions',
      children: [
        {
          name: 'Computational Photography Solutions for Smartphone',
          toPath: '',
          key: 'SmartphoneVisualSolutions',
          children: [
            {
              name: 'Turbo Fusion™ Products for Smartphone',
              toPath: '',
              key: 'MTurboFusion',
            },
            {
              name: 'AIGC Large Model Solutions for Smartphone',
              toPath: '',
              key: 'MAigcSolutions',
            },
          ],
        },
      ],
    },
    {
      name: 'Partnership',
      toPath: '',
      key: 'MPartnership',
    },
    {
      name: 'About us',
      toPath: '',
      key: 'MAboutUs',
    },
    {
      name: 'Contact',
      toPath: '',
      key: 'MContact',
    },
  ],
];
const navList = ref([
  [
    {
      name: 'Automotive Visual Solutions',
      toPath: '',
      key: 'ProductSolutions',
      children: [
        {
          name: 'Automotive Visual Solutions',
          toPath: '',
          key: 'AutomotiveVisualSolutions',
          children: [
            {
              name: 'In-cabin Visual Software Solutions',
              toPath: '',
              key: 'MInCabin',
            },
            {
              name: 'Auto-Parking Assist (APA) Solutions on a Single SoC',
              toPath: '',
              key: 'MAPA',
            },
            {
              name: 'Integrated ADAS Solutions',
              toPath: '',
              key: 'MADAS',
            },
            {
              name: 'Integrated Solutions for Commercial Vehicles',
              toPath: '',
              key: 'MCommercialVehicles',
            },
          ],
        },
      ],
    },
    {
      name: 'Computational Photography Solutions for Smartphone',
      toPath: '',
      key: 'SmartphoneVisualSolutions',
      children: [
        {
          name: 'Computational Photography Solutions for Smartphone',
          toPath: '',
          key: 'SmartphoneVisualSolutions',
          children: [
            {
              name: 'Turbo Fusion™ Products for Smartphone',
              toPath: '',
              key: 'MTurboFusion',
            },
            {
              name: 'AIGC Large Model Solutions for Smartphone',
              toPath: '',
              key: 'MAigcSolutions',
            },
          ],
        },
      ],
    },
    {
      name: 'Partnership',
      toPath: '',
      key: 'MPartnership',
    },
    {
      name: 'About us',
      toPath: '',
      key: 'MAboutUs',
    },
    {
      name: 'Contact',
      toPath: '',
      key: 'MContact',
    },
  ],
]);

const props = defineProps({
  isScroll: {
    type: Boolean,
    required: true,
  },
});
const router = useRouter();
const showNav = ref(false);
const logoNav = () => {
  if (showNav.value) {
    handleNav();
  }
};
const handleNav = () => {
  showNav.value = !showNav.value;
  document.body.style.overflow = showNav.value ? 'hidden' : 'auto';
};
watch(
  showNav,
  (val) => {
    if (!val) {
      navList.value = JSON.parse(JSON.stringify(oldNav));
    }
  },
  { deep: true }
);
const handleChildren = (item, idx) => {
  if (item.children && item.children.length && idx < 1) {
    navList.value.push(item.children);
  }
};
const toPage = (obj) => {
  navList.value = JSON.parse(JSON.stringify(oldNav));
  router.push(obj);
};
const handleBack = (index) => {
  navList.value.splice(1, index);
};
</script>
<style lang="less" scoped>
.scrolled {
  background-color: #fff; /* 黑色背景，透明度50% */
}
header {
  z-index: 99;
  // background-image: linear-gradient(180deg, rgba(0, 0, 0, 40%), #00000000 100%);
  // background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  transition: all 0.5s ease;
  .top_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 375px;

    box-sizing: border-box;
    height: 40px;
    padding: 0 24px;
    .logo {
      float: left;
      width: 88px;
      height: 40px;
      img {
        display: block;
        height: 100%;
      }
    }
    .nav {
      width: 22px;
      height: 18px;
      img {
        width: 100%;
      }
    }
  }
  .nav-warpper {
    position: absolute;
    top: 40px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 375px;
    height: 100vh;
    .nav-list {
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(to bottom, #fff, #eaeaeb);
      width: 375px;
      max-height: calc(100% - 40px);
      overflow: auto;
      text-align: left;
      .item-language {
        color: #999;
        font-size: 16px;
        padding: 12px 16px;
        text-align: center;
        a {
          font-size: 16px;
        }
      }
      .nav-item {
        box-sizing: border-box;
        padding: 12px 16px;
        font-size: 16px;
        display: flex;
        .nav-title {
          font-size: 16px;
        }
        &::before {
          content: '';
          margin-right: 8px;
          display: inline-block;
          width: 2px;
          background-color: #e83021;
        }
      }
      .sub-item {
        font-size: 16px;
        color: #555555;
        padding: 12px 26px;
      }
      .back {
        font-size: 16px;
        color: #555555;
        padding: 12px 26px;
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
          transform: rotateZ(180deg);
        }
      }
    }
  }
}
/* 过渡进入的效果 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s linear;
}

/* 过渡开始和结束的效果 */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
